import { Matrix4, PointsMaterial } from "three";
import { LodPointCloud } from "../Lod/LodPointCloud";
import { OptimizationHint } from "../Lod/VisibleNodeStrategy";
import { AdaptivePointsMaterial } from "../Materials/AdaptivePointsMaterial";
import { EyeDomePass } from "../PostProcessing/EyeDomePass";
import { MipGapFillPass } from "../PostProcessing/MipGapFillPass";
import { SubScenePipeline } from "../PostProcessing/SubScenePipeline";
import { RenderingPolicy } from "./RenderingPolicy";

const LOD_CLOUD_SUBSAMPLE_FRACTION = 0.3;

const MAX_FETCHED_NODES_BEFORE_INVALIDATION = 32;

/**
 * A class to encapsulate logic to render a LOD point cloud with different tradeoffs
 * of quality/performance.
 */
export class DynamicLodCloudRendering implements RenderingPolicy {
	#lodCloud: LodPointCloud | undefined = undefined;
	#pcScene: SubScenePipeline | undefined = undefined;
	#gapFillPass: MipGapFillPass | undefined = undefined;
	#eyeDomePass: EyeDomePass | undefined = undefined;
	#nodesReady = 0;
	#monitorConnected = false;
	#cloudConnected = false;
	#dynamic = false;
	#subsampleFraction = LOD_CLOUD_SUBSAMPLE_FRACTION;
	#lastCloudPose = new Matrix4();
	/** Whether gap filling should be enabled after camera movement */
	#gapFillingEnabled = true;
	/** Whether eye dome should be enabled after camera movement */
	#eyeDomeEnabled = false;

	/**
	 * Whether the point cloud is visible or not.
	 * If the point cloud is visible, then gap fill and subsampled rendering
	 * settings are adjusted when the camera switches between being still and
	 * moving, to achieve high performance on low-end GPUs.
	 */
	cloudVisible = true;

	/**
	 * How many new point cloud nodes should be fetched before triggering a scene invalidation
	 */
	maxFetchedNodesBeforeInvalidation = MAX_FETCHED_NODES_BEFORE_INVALIDATION;

	/**
	 * Constructs an instance of dynamic lod cloud rendering.
	 * This constructor sets the 'dynamic' property to false by default.
	 */
	constructor() {
		this.onCameraStartedMoving = this.onCameraStartedMoving.bind(this);
		this.onCameraStoppedMoving = this.onCameraStoppedMoving.bind(this);
		this.onNodeReady = this.onNodeReady.bind(this);
		this.onEverythingDownloaded = this.onEverythingDownloaded.bind(this);
		this.dynamic = false;
	}

	/**
	 * Finds the gap filling pass in the given subscene pipeline.
	 *
	 * @param s The subscene to search the gap filling pass into.
	 */
	#findingPostprocessingPasses(s: SubScenePipeline): void {
		for (const p of s.composer.passes) {
			if (p instanceof MipGapFillPass) {
				this.#gapFillPass = p;
			} else if (p instanceof EyeDomePass) {
				this.#eyeDomePass = p;
			}
		}
	}

	/**
	 *
	 * @param s the subscene pipeline.
	 */
	#checkPostprocessingPasses(s: SubScenePipeline): void {
		if (this.#gapFillPass && this.#eyeDomePass) return;
		this.#findingPostprocessingPasses(s);
	}

	/**
	 *
	 * @param lod The new point cloud to manage
	 */
	#connectCloud(lod: LodPointCloud): void {
		if (!this.#cloudConnected) {
			lod.nodeReady.on(this.onNodeReady);
			lod.allPointsReceived.on(this.onEverythingDownloaded);
			this.#cloudConnected = true;
		}
	}

	/**
	 * Stops managing the current point cloud, setting the internal handle to undefined.
	 */
	#disconnectCloud(): void {
		if (this.#lodCloud) {
			this.#lodCloud.nodeReady.off(this.onNodeReady);
			this.#lodCloud.allPointsReceived.off(this.onEverythingDownloaded);
			this.#cloudConnected = false;
		}
	}

	/**
	 *
	 * @param scene The subscene whose camera monitor is going to be listened.
	 */
	#connectCameraMonitor(scene: SubScenePipeline): void {
		if (!this.#monitorConnected) {
			scene.cameraMonitor.cameraStartedMoving.on(this.onCameraStartedMoving);
			scene.cameraMonitor.cameraStoppedMoving.on(this.onCameraStoppedMoving);
			this.#monitorConnected = true;
		}
	}

	/**
	 *
	 * @param scene The subscene whose camera monitor is being disconnected from.
	 */
	#disconnectCameraMonitor(scene: SubScenePipeline): void {
		scene.cameraMonitor.cameraStartedMoving.off(this.onCameraStartedMoving);
		scene.cameraMonitor.cameraStoppedMoving.off(this.onCameraStoppedMoving);
		this.#monitorConnected = false;
	}

	/**
	 * Updating this object's status according to the subscene being present, the cloud being
	 * connected, and dynamic rendering being on or off.
	 */
	#updateStatus(): void {
		if (!this.#pcScene) return;
		if (!this.#lodCloud) return;
		if (this.#pcScene.cameraMonitor.cameraMoving) {
			this.onCameraStartedMoving();
		} else {
			this.onCameraStoppedMoving();
		}
	}

	/** Sets fast rendering settings to keep a high framerate when the camera is moving */
	onCameraStartedMoving(): void {
		if (!this.#pcScene) return;
		this.#pcScene.renderOnDemand = false;
		if (!this.#lodCloud || !this.cloudVisible) return;
		this.#lodCloud.visibleNodesStrategy.optimizationHint = OptimizationHint.cameraChanging;
		if (this.#dynamic) {
			this.#checkPostprocessingPasses(this.#pcScene);
			if (this.#gapFillPass) this.#gapFillPass.enabled = false;
			if (this.#eyeDomePass) this.#eyeDomePass.enabled = this.#lodCloud.monochrome;
			const mat = this.#lodCloud.material;
			if (mat instanceof PointsMaterial && !mat.sizeAttenuation) {
				mat.size = 4;
			} else if (mat instanceof AdaptivePointsMaterial) {
				mat.maxSize = 6;
				mat.minSize = 4;
			}
			this.#lodCloud.setSubsampledRenderingOn(true);
			this.#lodCloud.setSubsampledRenderingFraction(this.#subsampleFraction);
		}
	}

	/** Sets high quality rendering settings when the camera stops moving. */
	onCameraStoppedMoving(): void {
		if (!this.#pcScene) return;
		this.#pcScene.renderOnDemand = true;
		this.#pcScene.invalidate();
		if (!this.#lodCloud || !this.cloudVisible) return;
		this.#checkPostprocessingPasses(this.#pcScene);
		if (this.#gapFillPass) this.#gapFillPass.enabled = this.gapFillingEnabled;
		if (this.#eyeDomePass) this.#eyeDomePass.enabled = this.eyeDomeEnabled;
		this.#lodCloud.visibleNodesStrategy.optimizationHint = OptimizationHint.cameraChangingOnce;
		const mat = this.#lodCloud.material;
		if (mat instanceof PointsMaterial && !mat.sizeAttenuation) {
			mat.size = 2;
		} else if (mat instanceof AdaptivePointsMaterial) {
			mat.resetToTargetSize();
		}
		this.#lodCloud.setSubsampledRenderingOn(false);
	}

	#nodesReadyBeforeInvalidating(): number {
		if (!this.#lodCloud) return this.maxFetchedNodesBeforeInvalidation;
		const nodesInUse = this.#lodCloud.nodesInGPU.size;
		if (nodesInUse < 8) return 1;
		if (nodesInUse < 16) return 2;
		if (nodesInUse < 32) return 4;
		return this.maxFetchedNodesBeforeInvalidation;
	}

	/**
	 * When a new LOD cloud node is downloaded, we may want to invalidate the scene and
	 * re-render the point cloud with high quality.
	 */
	onNodeReady(): void {
		if (!this.#pcScene || !this.#lodCloud) return;
		if (this.#pcScene.cameraMonitor.cameraMoving || this.#pcScene.renderOnDemand === false) return;
		this.#nodesReady++;
		if (this.#nodesReady > this.#nodesReadyBeforeInvalidating()) {
			this.#nodesReady = 0;
			this.#pcScene.invalidate();
		}
	}

	/**
	 * When all LOD nodes are downloaded for the current camera frustum,
	 * the point cloud is re-rendered with high quality.
	 */
	onEverythingDownloaded(): void {
		if (!this.#pcScene) return;
		if (this.#pcScene.cameraMonitor.cameraMoving || this.#pcScene.renderOnDemand === false) return;
		this.#nodesReady = 0;
		this.#pcScene.invalidate();
	}

	/** @returns whether the rendering settings are dynamically changed with the camera being still or moving. Default is false. */
	get dynamic(): boolean {
		return this.#dynamic;
	}

	/** Sets whether the rendering settings are dynamically changed with the camera being still or moving. */
	set dynamic(d: boolean) {
		this.#dynamic = d;
		this.#updateStatus();
	}

	/** @returns the currently managed point cloud, or 'undefined' */
	get lodCloud(): LodPointCloud | undefined {
		return this.#lodCloud;
	}

	/**
	 * Sets the currently managed point cloud. Setting 'undefined' causes this object
	 * to forget about the cloud.
	 */
	set lodCloud(lod: LodPointCloud | undefined) {
		if (this.#lodCloud === lod) return;
		// resetting parameters on old cloud
		if (this.#lodCloud) {
			if (this.#lodCloud.material instanceof PointsMaterial && !this.#lodCloud.material.sizeAttenuation) {
				this.#lodCloud.material.size = 2;
			}
			this.#lodCloud.setSubsampledRenderingOn(false);
			this.#lodCloud.visibleNodesStrategy.optimizationHint = OptimizationHint.cameraChanging;
			this.#disconnectCloud();
		}
		if (lod) {
			this.#lodCloud = lod;
			this.#connectCloud(this.#lodCloud);
			this.#updateStatus();
		} else {
			this.#lodCloud = undefined;
		}
	}

	/** @returns the subscene that is rendering the point cloud. */
	get subScene(): SubScenePipeline | undefined {
		return this.#pcScene;
	}

	/** Sets the subscene that is rendering the point cloud. */
	set subScene(s: SubScenePipeline | undefined) {
		if (this.#pcScene === s) return;
		if (this.#pcScene) {
			// resetting parameters on old scene
			this.#pcScene.renderOnDemand = false;
			this.#disconnectCameraMonitor(this.#pcScene);
			if (this.#gapFillPass) this.#gapFillPass.enabled = this.gapFillingEnabled;
			this.#gapFillPass = undefined;
		}
		this.#pcScene = s;
		if (this.#pcScene) {
			this.#findingPostprocessingPasses(this.#pcScene);
			this.#connectCameraMonitor(this.#pcScene);
			this.#updateStatus();
		} else {
			this.#gapFillPass = undefined;
		}
	}

	/** @returns the fraction of points that we want to see when the camera is moving, to save performance */
	get subsampleFraction(): number {
		return this.#subsampleFraction;
	}

	/**
	 * Sets the fraction of points that we want to see when the camera is moving, to save performance.
	 * Allowed values are strictly greater than 0 and strictly smaller than 1.
	 */
	set subsampleFraction(s: number) {
		if (s > 0 && s < 1) {
			this.#subsampleFraction = s;
		}
	}

	/** @returns whether eye dome should be enabled */
	get eyeDomeEnabled(): boolean {
		return this.#eyeDomeEnabled;
	}

	/** Sets whether eye dome should be enabled */
	set eyeDomeEnabled(e: boolean) {
		if (this.#eyeDomeEnabled === e) return;
		this.#eyeDomeEnabled = e;
		this.#updateStatus();
	}

	/** @returns whether gap filling should be enabled */
	get gapFillingEnabled(): boolean {
		return this.#gapFillingEnabled;
	}

	/** Sets whether gap filling should be enabled */
	set gapFillingEnabled(g: boolean) {
		if (this.#gapFillingEnabled === g) return;
		this.#gapFillingEnabled = g;
		this.#updateStatus();
	}

	/** Disconnecting all signals and resetting all parameters. */
	dispose(): void {
		this.dynamic = false;
		this.subScene = undefined;
		this.lodCloud = undefined;
	}

	/** @returns whether the monitored camera is moving or still. */
	get cameraMoving(): boolean {
		return this.#pcScene ? this.#pcScene.cameraMonitor.cameraMoving : false;
	}

	/** @inheritdoc */
	sceneChanged(): boolean {
		if (!this.#lodCloud) return false;
		if (!this.#lastCloudPose.equals(this.#lodCloud.matrixWorld)) {
			this.#lastCloudPose.copy(this.#lodCloud.matrixWorld);
			return true;
		}
		return false;
	}

	/** Invalidates the attached subscene */
	invalidate(): void {
		if (!this.#pcScene || this.#pcScene.renderOnDemand === false) return;
		this.#pcScene.invalidate();
		if (this.#lodCloud) this.#lodCloud.visibleNodesStrategy.optimizationHint = OptimizationHint.cameraChangingOnce;
	}
}
