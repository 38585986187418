import { TypedEvent, assert, isDownloadAbortError } from "@faro-lotv/foundation";
import { PointCloudBufferGeometry } from "../PointCloud";
import { PointCloudChunk } from "../PointCloud/PointCloudChunk";
import { BinaryHeap } from "../Utils";
import { LodNodeFetch, LodTree, NodeState } from "./LodTree";
import { WeightedNode } from "./VisibleNodeStrategy";

/**
 * An interface to be implemented by any class who wants to use the LodTreeFetcher
 */
export interface FetcherClient {
	/** A reference to the lod tree fetcher */
	readonly lodTreeFetcher: LodTreeFetcher;
	/** A reference to the tree */
	readonly tree: LodTree;
}

/**
 * A pair specifying a client and its weight. This is used to keep track, for each
 * node, which client requested the node and with which weight.
 */
type WeightedFetcherClient = {
	/** The fetcher client */
	client: FetcherClient;
	/** The weight associated to the client */
	weight: number;
};

/**
 * A class capable of fetching, downloading and caching of nodes of a LodTree
 */
export class LodTreeFetcher {
	/** The LOD tree data structure. */
	#tree: LodTree;
	/**
	 * Array of which clients are using each node.
	 */
	#nodeReferenceClients: WeightedFetcherClient[][] = [];
	/**
	 * This event is emitted when a node is downloaded
	 */
	nodeReady = new TypedEvent<{ nodeIdx: number; points: PointCloudBufferGeometry }>();
	/**
	 * This event is emitted when a node fetch fails without being canceled
	 */
	nodeFailed = new TypedEvent<{ nodeIdx: number; reason: unknown }>();
	/**
	 * This event is emitted when all requested nodes are downloaded.
	 */
	everythingDownloaded = new TypedEvent<void>();
	/**
	 * The priority queue holding the nodes that are waiting for download.
	 * The queue is based on a min-heap structure, so the nodes with higher
	 * weights are prioritized.
	 */
	#nodesToDownloadQueue = new BinaryHeap<WeightedNode>(
		(weightedNode) => -weightedNode.weight,
		(weightedNode) => weightedNode.id,
	);
	/**
	 * The list of nodes whose points are currently downloading, implemented as a map node idx -> LodNodeFetch object.
	 * Only '_maxNodesToDownloadAtOnce' nodes are allowed to be in this list.
	 */
	#downloadingNodes = new Map<number, LodNodeFetch | null>();
	/**
	 * The list of the downloaded nodes
	 */
	#downloadedNodes = new Map<number, PointCloudBufferGeometry>();
	/**
	 * The maximum number of nodes to downloaded at once.
	 * If this value is too high, then errors may happen such as nodes fetched
	 * and never received back.
	 */
	#maxNodesToDownloadAtOnce = 4;

	/**
	 * Constructs the tree fetcher.
	 *
	 * @param tree The lod tree data structure.
	 */
	constructor(tree: LodTree) {
		this.#tree = tree;
	}

	/**
	 * Dispose of this fetcher resources if there are no attached clients
	 */
	disposeIfNoClients(): void {
		if (this.#nodeReferenceClients.length === 0) {
			this.dispose();
		}
	}

	/**
	 * Clean up all resources managed by this fetcher, remove reference to all the clients
	 * and dispose all the geometries still in use
	 */
	dispose(): void {
		// Remove all reference to clients
		this.#nodeReferenceClients = [];

		// Abort all pending fetches
		for (const pending of this.#downloadingNodes.values()) {
			if (pending) {
				pending.abort();
			}
		}
		this.#downloadingNodes.clear();

		// Dispose all geometries
		for (const node of this.#downloadedNodes.values()) {
			node.dispose();
		}
		this.#downloadedNodes.clear();
	}

	/**
	 * After node n has been downloaded (or its download has been canceled), here we remove the LodNodeFetch object that
	 * supported the download. Also, we call 'processDownloads' again to check whether there are other nodes in queue
	 * that need to be downloaded.
	 *
	 * @param n The downloaded node.
	 */
	#cleanupDownload(n: number): void {
		const node = this.#tree.getNode(n);
		if (node.state !== NodeState.InUse && node.state !== NodeState.NotInUse) {
			node.state = NodeState.NotInUse;
		}
		this.#downloadingNodes.delete(n);
		if (this.nodesPending === 0) {
			this.everythingDownloaded.emit();
		}
	}

	/**
	 * Just after download, loads a node's points to GPU.
	 *
	 * @param nodeIdx Idx of node that is being loaded to GPU
	 * @param points Point data to be loaded
	 * @returns the cached geometry
	 */
	#handlePointsDownloaded(nodeIdx: number, points: PointCloudChunk): PointCloudBufferGeometry {
		const geometry = new PointCloudBufferGeometry(points);
		this.#downloadedNodes.set(nodeIdx, geometry);
		this.#tree.getNode(nodeIdx).state = NodeState.InUse;
		return geometry;
	}

	/**
	 * Logs download error to console if it is not deliberate cancellation.
	 *
	 * In this function, we filter out error messages that are deliberate cancellations of downloads,
	 * in order to not pollute the browser console.
	 *
	 * @param n The node that was downloading
	 * @param error The error message
	 */
	#handleDownloadError(n: number, error: Error | string): void {
		const downloadCanceled = isDownloadAbortError(error);
		if (!downloadCanceled) {
			this.nodeFailed.emit({ nodeIdx: n, reason: error });
		}
		this.#tree.getNode(n).state = NodeState.NotInUse;
		if (this.#downloadingNodes.has(n)) {
			this.#downloadingNodes.delete(n);
		}
		if (this.#downloadedNodes.has(n)) {
			this.#downloadedNodes.delete(n);
		}
		this.#nodeReferenceClients[n] = [];
	}

	/**
	 * Main algorithm for node points download and management.
	 */
	#processDownloads(): void {
		// Process the queue of nodes to download. Pop nodes from there into _downloadingNodes until _downloadingNodes is full.
		while (this.#nodesToDownloadQueue.length > 0 && this.#downloadingNodes.size < this.#maxNodesToDownloadAtOnce) {
			// Below it is guaranteed that the queue is nonempty so it is safe to use a type assertion to pop the queue.
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const n = this.#nodesToDownloadQueue.pop() as WeightedNode;
			const node = this.#tree.getNode(n.id);
			if (node.state !== NodeState.WaitingForDownload) {
				continue;
			}
			node.state = NodeState.Downloading;
			// Line below is needed to make sure that we never download at once more than the required amount of nodes.
			this.#downloadingNodes.set(n.id, null);
			this.#tree
				.getNodePoints(n.id)
				.then((f: LodNodeFetch): Promise<PointCloudChunk> => {
					const pp = f.points();
					this.#downloadingNodes.set(n.id, f);
					return pp;
				})
				.then((points: PointCloudChunk) => {
					const geometry = this.#handlePointsDownloaded(n.id, points);
					// Emit event of node downloaded
					this.nodeReady.emit({ nodeIdx: n.id, points: geometry });
				})
				.catch((error: Error | string) => {
					// Log download error to console if it is not deliberate cancellation.
					this.#handleDownloadError(n.id, error);
				})
				.finally(() => {
					// Either in case of successful download or error, or cancellation, we cleanup
					// the download handler and remove n from _downloadingNodes.
					this.#cleanupDownload(n.id);
				});
		}
	}

	/**
	 * Starts the download of the requested nodes. If any of the requested nodes is already in use,
	 * this function silently does nothing with these nodes.
	 *
	 * @param toLoad The list of nodes that came into visibility and must be loaded to GPU
	 * @param requester The object that requested the node
	 */
	requestNodes(toLoad: WeightedNode[], requester: FetcherClient): void {
		for (const n of toLoad) {
			const node = this.#tree.getNode(n.id);
			let clients = this.#nodeReferenceClients[n.id];
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
			if (!clients) {
				clients = [];
				this.#nodeReferenceClients[n.id] = clients;
			}

			// Update the stored weight for the current
			// fetcher and compute the highest weight among
			// all fetchers
			let weightedFetcher = undefined;
			let maxWeight = n.weight;
			for (const client of clients) {
				if (client.client === requester) {
					client.weight = n.weight;
					weightedFetcher = client;
				}
				if (client.weight > maxWeight) {
					maxWeight = client.weight;
				}
			}
			if (!weightedFetcher) {
				// Add the requester to the list of clients using the node
				clients.push({ client: requester, weight: n.weight });
			}
			// we only enqueue nodes that are not yet enqueued for download.
			switch (node.state) {
				case NodeState.NotInUse: {
					node.state = NodeState.WaitingForDownload;
					const nodeIndex = this.#nodesToDownloadQueue.getIndex(n);
					if (nodeIndex === undefined) {
						this.#nodesToDownloadQueue.push({ id: n.id, weight: maxWeight });
					} else {
						const wn = this.#nodesToDownloadQueue.content[nodeIndex];
						if (wn.weight !== maxWeight) {
							if (wn.weight < maxWeight) {
								wn.weight = maxWeight;
								this.#nodesToDownloadQueue.bubbleUp(nodeIndex);
							} else {
								wn.weight = maxWeight;
								this.#nodesToDownloadQueue.sinkDown(nodeIndex);
							}
						}
					}
					break;
				}
				case NodeState.InUse: {
					// This happens if another client has already requested this node, and it has set it to inUse.
					// Therefore we already have it in the _downloadedNodes list and we can just return its value with getNodeInUse.
					const nodeInUse = this.getNodeInUse(n.id);
					assert(nodeInUse, "The requested node does not exist");
					this.nodeReady.emit({
						nodeIdx: n.id,
						points: nodeInUse,
					});
					break;
				}
				case NodeState.WaitingForDownload: {
					const nodeIndex = this.#nodesToDownloadQueue.getIndex(n);
					if (nodeIndex !== undefined) {
						const wn = this.#nodesToDownloadQueue.content[nodeIndex];
						if (wn.weight !== maxWeight) {
							if (wn.weight < maxWeight) {
								wn.weight = maxWeight;
								this.#nodesToDownloadQueue.bubbleUp(nodeIndex);
							} else {
								wn.weight = maxWeight;
								this.#nodesToDownloadQueue.sinkDown(nodeIndex);
							}
						}
					}
					break;
				}
			}
		}
		this.#processDownloads();
	}

	/**
	 * Communicates to the fetcher that client 'requester' does not need anymore the node 'nodeIdx'
	 *
	 * @param nodeIdx The idx of the node to release
	 * @param requester The object that requested to release a node
	 */
	releaseNode(nodeIdx: number, requester: FetcherClient): void {
		const clients = this.#nodeReferenceClients[nodeIdx];
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
		if (!clients) return;
		const index = clients.findIndex((wf) => wf.client === requester);
		if (index >= 0 && index < clients.length) {
			clients.splice(index, 1);
		}
		if (clients.length === 0) {
			const node = this.#tree.getNode(nodeIdx);
			if (node.state === NodeState.Downloading) {
				this.#stopDownloadingNode(nodeIdx);
			}
			node.state = NodeState.NotInUse;
			const cache = this.#downloadedNodes.get(nodeIdx);
			if (cache) {
				cache.dispose();
				this.#downloadedNodes.delete(nodeIdx);
			}
		}
	}

	/**
	 * Stops downloading a specific node
	 *
	 * @param nodeIdx Index of the node that has to be stopped from downloading
	 */
	#stopDownloadingNode(nodeIdx: number): void {
		this.#tree.getNode(nodeIdx).state = NodeState.NotInUse;
		const p = this.#downloadingNodes.get(nodeIdx);
		if (p) {
			p.abort();
			this.#downloadingNodes.delete(nodeIdx);
		}
	}

	/**
	 * Returns how many requesters are using node 'nodeIdx'.
	 *
	 * @param nodeIdx Index of the node to get the reference count from
	 * @returns The client count of the node
	 */
	getClientCountOfNode(nodeIdx: number): number {
		return this.#nodeReferenceClients[nodeIdx]?.length ?? 0;
	}

	/** @returns how many tree nodes are arriving, in queue or already in download. */
	get nodesPending(): number {
		return this.downloadingNodesCount + this.nodesWaitingForDownloadCount;
	}

	/** @returns how many tree nodes are currently being downloaded. */
	get downloadingNodesCount(): number {
		return this.#downloadingNodes.size;
	}

	/** @returns how many tree nodes are currently waiting to be downloaded. */
	get nodesWaitingForDownloadCount(): number {
		return this.#nodesToDownloadQueue.length;
	}

	/** @returns how many tree nodes are currently being downloaded. */
	get downloadedNodesCount(): number {
		return this.#downloadedNodes.size;
	}

	/** @returns How many nodes can be downloaded simultaneously. */
	get maxNodesToDownloadAtOnce(): number {
		return this.#maxNodesToDownloadAtOnce;
	}

	/** Sets how many nodes can be downloaded simultaneously. */
	set maxNodesToDownloadAtOnce(m: number) {
		if (m < 1 || m > 16) {
			console.warn("LodTreeFetcher error: max nodes to download at once should lie in the interval [1, 16].");
		}
		this.#maxNodesToDownloadAtOnce = m;
	}

	/**
	 * Returns the node data of node 'n' if it is in use, 'undefined' otherwise.
	 *
	 * @param n The node index.
	 * @returns the data of a node in use
	 */
	getNodeInUse(n: number): PointCloudBufferGeometry | undefined {
		return this.#downloadedNodes.get(n);
	}

	/** @returns the LOD tree */
	get tree(): LodTree {
		return this.#tree;
	}

	/**
	 * Given a fetcher client, returns the list of nodes that the
	 * specific client is using. Useful in cleanup functions to make
	 * sure that a client does not use nodes and can be safely deallocated.
	 *
	 * @param f The fetcher client.
	 * @returns The list of nodes currently in use by the give client.
	 */
	getNodesInUseByClient(f: FetcherClient): number[] {
		const ret = new Array<number>();
		for (let i = 0; i < this.#nodeReferenceClients.length; ++i) {
			const clients = this.#nodeReferenceClients[i];
			// Despite the typing, clients might still be undefined
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			if (clients?.find((wf) => wf.client === f)) {
				ret.push(i);
			}
		}
		return ret;
	}

	/**
	 * Remove all reference of a client from the fetcher releasing all nodes
	 * that client was keeping alive
	 *
	 * @param client the client to remove
	 */
	removeClient(client: FetcherClient): void {
		const nodes = this.getNodesInUseByClient(client);
		for (const n of nodes) {
			this.releaseNode(n, client);
		}
	}
}
